import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { graphql, Link } from 'gatsby'

const Blog = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes
  return (
    <>
      <Seo pageTitle={'Blog'} />
      <Layout>
        <div id='blog-list' className='container'>
          <div className='row mt-5'>
            <div className='offset-lg-3 col-lg-6 mb-3'>
              <h1 className='display-4 mb-5'>All Blog Posts</h1>
            </div>
          </div>
          {posts.map((post) => {
            return (
              <div key={post.id} className='row justify-content-center'>
                <div className='col-lg-8 mb-3'>
                  <Link to={post.fields.slug}>
                    <h3>{post.frontmatter.title}</h3>
                  </Link>
                  <p className='small text-muted'>{post.frontmatter.date}</p>
                  <p>{post.excerpt}</p>
                  <Link to={post.fields.slug}>read more</Link>
                  <hr />
                </div>
              </div>
            )
          })}
        </div>
      </Layout>
    </>
  )
}

export default Blog

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
    ) {
      nodes {
        id
        excerpt(pruneLength: 240)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "dddd DD MMMM YYYY")
          title
        }
        timeToRead
      }
    }
  }
`
